.sidebar{
    background-color: #111111;

    width: 250px;
    height: 100vh;
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-admin{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0ddff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #111111;

}

.header-admin{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    background-color: #111111;
}
.header-admin p{
    margin-left: 10px;
    color: #fff;
    font-size: 16px;
}

.navigation-admin{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}   
.nav-link{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    transition-duration: 0.3s;
    cursor: pointer;
    background-color: rgba(189, 189, 189, 0.02);
    box-shadow: 0 3px rgba(0, 0, 0, 0.1);

    
}
.nav-link:hover{
    background-color: #111111;
    
    background-color: rgba(189, 189, 189, 0.2);
    /* background-color: rgba(255, 255, 255, 0.705); */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    

}

.nav-icon{
    width: 20px;

}
.nav-title{
    margin-left: 20px;
    color: #D8D8D8;
}

.nav-active{
    background-color: rgba(135, 144, 150, 0.384);
    background-color: rgba(43, 134, 165, 0.384);
    /* background-color: rgba(255, 255, 255, 0.705); */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

}


