.dashboard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}
.mainAdmin{
    background-color: #f7f7f7;
    width: calc(100% - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadpage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.loadpage img{
    width: 100px;
}

.process{
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
}

.title-admin{
    width: 100%;    
    background-color: #111111;
    color: #f7f7f7;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
}


.opacity{
    opacity: 0;
}

.linkToSite{
    color: #eeeeee;
    font-weight: 500;
    text-decoration: underline;
}

























.my-cargo{
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
}

.section-my-cargo{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}




.status-list{
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.3);
    margin: 50px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);    
}
.status-list-title{
    font-size: 22px;
    font-weight: 500;
    text-align: center;

}

.statuses{
    margin-top: 10px;
}   

.status-el{
    padding: 15px;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.5);
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.status-el:hover{
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);    

}

.trash{
    width: 20px;
}

.form-addTrack{
    margin: 20px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;

}
.input-statusAdd{
    border: 1px solid rgba(129, 129, 129, 0.5);
    width: 100%;
    border-radius: 4px 0px 0px 4px;
    padding: 10px 15px;
    border-right: 0;

}

.input-statusAdd:focus{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);    

}

.statusAdd-button{
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 5px 15px;
    border: none;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;

}
.statusAdd-button:hover{
    background-color: green;
    background-color: #4285F4;

}

/* filial */

.form-filialAdd{
    display: flex;
    flex-direction: column;
    margin-top: 10px;

}

.inputs-container-filial{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.input-filialAdd{
    border: 1px solid rgba(129, 129, 129, 0.5);
    width: 100%;
    border-radius: 4px ;
    padding: 10px 15px;
    margin: 5px 0;
}
.input-filialAdd:focus{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);    

}

.filialAdd-button{
    margin-top: 10px;
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius:4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;
}
.filialAdd-button:hover{
    background-color: #4285F4;

}
.filial-el{
    padding: 15px;
    font-size: 16px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid rgba(129, 129, 129, 0.5);
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}





/* AddTrack */
.mainAdmin-container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
    
}
.mini-contianer{
    display: flex;
    
}
.status-block {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: rgb(255, 255, 255);
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: rgb(96, 146, 96); /* Цвет фона */
    background-color: #ffffff; /* Цвет фона */
  

    display: flex;
    align-items: center;
    justify-content: space-between;


}
.status-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    transform: scale(1.03);
}
.status-block p{
    margin: 0 5px;
}
.status-block img{
    width: 25px;
    margin: 0 5px;
}
.excel-block{
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.excel-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    transform: scale(1.03);
}

.excel-block p{
    margin: 0 5px;
}
.excel-block img{
    width: 25px;
    margin: 0 5px;
}


.date-container{
    margin: 0px 20px ;
}
.h3-date{
    font-size: 16px;
    margin: 0 0 10px 0;
    text-align: center;
    font-weight: 500;
}
.date-block {
    width: 220px; /* Ширина Select по размеру контейнера */
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    text-align: center;

}
  

.scaner-block{
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.scaner-block:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}

.scaner-block p{
    margin: 0 5px;
}
.scaner-block img{
    width: 25px;
    margin: 0  20px 0 0;

}

.scaner-input{
    padding: 15px 20px;
    border-radius: 10px; /* Закругление углов */
    width: 100%;
}
.scaner-input::placeholder{
    color: #111111;
}


.scaner-block.focus {
    border-color: #007bff;
}

.status-pop:hover{
    background-color: #fff;
}


.modalExcel{
    position: fixed;
    top: 40px;
    left: 280px;
    background-color: #fff;
    width: 75%;
    height: 80%;

    border: 1px solid #ccc;
    padding: 20px 30px 40px 30px;
    border-radius: 10px; /* Закругление углов */
    text-align: center;
    margin: 20px 0;
    color: #111111;
    background-color: #ffffff; /* Цвет фона */
    border-color: #007bff; /* Цвет границы при наведении */
  

    display: flex;
    flex-direction: column;
}
.modal-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.modal-header h2{
    font-size: 23px;
    font-weight: 500;
    color: #303030;

}
.close{
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/img/closeA.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    transition-duration: 0.3s;
}

.close:hover{
    background-image: url('../../../assets/img/closeB.png');

}




.textarea{
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    margin: 30px 0 0 0;
    color: #111111;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    padding: 20px;
    height: 100%;

}
.textarea:hover {
    border-color: #007bff; /* Цвет границы при наведении */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
}




.buttonExcel{
    margin-top: 10px;
    background-color: #4285F4;
    background-color: green;
    color: #FFFFFF;
    padding: 10px;
    border: none;
    border-radius:4px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration:  0.3s;
}
.buttonExcel:hover{
    background-color: #4285F4;

}


.modal-load{
    z-index: 10000;
    position: absolute;
    top: 30%;
    left: 40%;
    width: 225px;
    height: 100px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px; /* Закругление углов */
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-load img{
    width: 30px;
    
  }

.modal-load p{
    font-weight: 500;
    margin: 0 0 0 10px;
}

.loading img{
    display: inline-block;
    animation: rotate 2s linear infinite;
    
  }
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }




  /* UserList */


.users-container{
    width: 100%;
    height: 100%;

    padding: 20px;

}

.header-bar{
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #ffffff; /* Цвет фона */
    
    display: flex;
    align-items: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);    

}

.search-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor:text;

}

.search-bar:hover{
    border-color: #007bff; /* Цвет границы при наведении */

}
.searchIcon{
    width: 17px;
}
.searchInput{
    width: 150px;
    background-color: #f8f8f8; /* Цвет фона */
    margin-left: 10px;
}
.filter-bar{
    display: flex;
    align-items: center;

}

.filter-point{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 11px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 14px;
    margin-left: 15px;
    transition-duration: 0.3s;
}

.filter-point:hover{
    border-color: #007bff; /* Цвет границы при наведении */
    background-color: #fff;
}
.filter-point-active{
    background-color: #fff;
    border-color: #007bff; /* Цвет границы при наведении */

}

.status-filter{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative; 
}

.statuses-popup {
    position: absolute;
    top: 100%; /* Position it below the filter-point */
    left: 20px;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 5px 0 5px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    border-radius: 5px;
}

.filter-point-status{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 11px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 14px;
    transition-duration: 0.3s;
    margin: 0 0 5px 0 ;
}


/*  */
.page-point{
    background-color: #f1f1f1; /* Цвет фона */
    border: 1px solid #ccc;
    padding: 5px 8px;
    border-radius: 5px;
    cursor:pointer;
    font-size: 12px;
    margin-left: 20px;
    transition-duration: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.page-point:hover{
    border-color: #007bff; /* Цвет границы при наведении */
}

.page-point input{
    width: 50px;
    padding: 5px;
    margin-left: 5px;
    border-radius: 5px;
    background-color: #fcfcfc;
    border: 1px solid #ccc;
}

.page-point input:focus{
    border-color: #007bff; /* Цвет границы при наведении */
}

.table-user{
    margin: 10px 0 0 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #ffffff; /* Цвет фона */
    padding: 5px;
}


.table {
    width: 100%;
    border-collapse: collapse;

}
  
.table th, .table td {
    border: 1px solid #ccc;
    padding: 8px;
}

.table thead {
    background-color: #EEEEEE;
}

/* settings */

.inputs-wrapper{
    padding: 20px 0 0 0;
    
}

.settingsGet{
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 4px;
    white-space: pre-wrap;
}


.date-container_mobile{
    display: none;
}

.page-point-bar{
    margin-top: 20px ;
    display: flex;
    justify-content: center;
}



@media only screen and (max-width: 320px) {
    
    .burger_container{
        display: flex;
    }
    .mainAdmin{
        width: 100%;

    }
    .opacity{
        display: none;
    }

    .mini-contianer{
        flex-direction: column-reverse;
    }
    .mainAdmin-container{
        padding: 0;
    }

    .burger_container {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30px;
        height: 25px;
    }

    .burgerLine {
        width: 100%;
        height: 3px;
        background-color: #fff;
        transition: all 0.3s ease;
    }
    
    .burger_container.open .burger__line1 {
        transform: translateY(11px) rotate(45deg);
    }
    
    .burger_container.open .burger__line2 {
        opacity: 0;
    }
    
    .burger_container.open .burger__line3 {
        transform: translateY(-11px) rotate(-45deg);
    }

    

    .sidebar{
        
        display: flex;
        background-color: #111111;
        position: fixed;
        top: 45px;
        left: -100vw; /* Начальное положение за пределами экрана */
        width: 100vw;
        height: 100%;
        z-index: 10000;
        transition-duration: 0.3s;
    }


    .sidebar.openSidebar {
       
        display: flex;
        flex-direction: column;
        align-items: center;
        transition-duration: 0.3s;
        left: 0; /* Меню видно на экране */
    }
  }


  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
.tdUserTrack{
    padding: 4px 10px;
    background-color: #99fdbb;
    border-radius: 5px;
}


.tdUserTrack.user-unknown {
    background-color: rgba(255, 255, 255, 0);
}


.totalCount{
    margin: 10px 0 0 15px;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 10px;
    font-size: 12px;
    width: 200px;
    text-align: center;
}